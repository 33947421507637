<template>
  <div
    class="sticky top-0 z-[500] flex-col items-center rounded-b-4xl bg-secondary-500 py-8 print:hidden"
  >
    <div class="container">
      <div class="flex items-center justify-between gap-4 sm:items-stretch">
        <NuxtLink
          class="z-[900] flex"
          to="/"
        >
          <img
            class="w-full max-sm:max-w-[7.5rem]"
            :src="`/images/logo-${locale}.svg`"
            alt="365 Dagen Bloemen - Logo"
          >
        </NuxtLink>

        <AppHeaderMenu
          class="max-lg:hidden"
          :menu="menu"
        />

        <AppHeaderSubMenu
          v-model:open="open"
          v-model:search-focus="searchFocus"
        />
      </div>
    </div>

    <AppHeaderMobileMenu
      v-model:open="open"
      v-model:search-focus="searchFocus"
      :menu="menu"
    />
    <AppHeaderSearchMobile v-model:search-focus="searchFocus" />
  </div>
</template>

<script lang="ts" setup>
const {locale} = useI18n();

const open = ref(false);
const searchFocus = ref(false);

const {status} = useAuth();
const {t} = useI18n();

const menu = computed(() => {
  const menu = [
    {label: t('pages.inspiration.title'), url: {name: 'inspiration'}},
    {label: t('pages.availability.title'), url: {name: 'availability'}},
    {label: t('pages.stories.title'), url: {name: 'stories'}},
    {label: t('pages.events.title'), url: {name: 'schedule'}},
  ];

  if (status.value === 'unauthenticated') {
    menu.push({label: t('pages.subscribe.title'), url: {name: 'subscribe'}});
  }

  return menu;
});
</script>

<style scoped>
.sticky {
  view-transition-name: header;
}
</style>
