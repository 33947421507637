<template>
  <div
    ref="rootEl"
    class="pointer-events-none absolute top-full mt-6 w-full -translate-y-8 px-6 opacity-0 drop-shadow-lg transition"
    :class="{'pointer-events-auto !translate-y-0 opacity-100' : searchFocus}"
  >
    <AppHeaderSearch
      v-model:search-focus="searchFocus"
      :always-expanded="true"
    />
  </div>
</template>

<script lang="ts" setup>
const searchFocus = defineModel<boolean>('searchFocus', {required: true});

const rootEl = ref<HTMLElement>();

onClickOutside(rootEl, () => searchFocus.value = false);
</script>
