<template>
  <TransitionsFadeUp>
    <div
      v-if="expanded && searchTerm.length >= 1"
      class="absolute right-0 top-6 flex w-full flex-col rounded-b-lg border border-grey-400 bg-white sm:top-8 lg:w-80"
    >
      <div class="mt-10" />
      <div>
        <div
          v-if="hasResults"
          class="max-h-96 overflow-auto overscroll-contain"
        >
          <div
            v-for="(result, key, resultIndex) in groupedResults"
            :key="resultIndex"
            class="flex flex-col gap-3 border-b border-grey-400 last:border-b-0"
          >
            <div class="px-6 pt-5 font-semibold capitalize">
              {{ $t(`search.result_type.${key.toLowerCase()}`) }}
            </div>
            <div class="flex flex-col gap-2 pb-5">
              <div
                v-for="({label, to, index}) in result"
                :key="index"
                ref="resultRefs"
              >
                <NuxtLink
                  :class="{'bg-grey-400' : selectedIndex === index}"
                  :to="localePath(to)"
                  class="mx-4 line-clamp-2 cursor-pointer rounded-lg px-2 py-1 text-lg text-grey no-underline hover:bg-grey-400"
                  @click="$emit('navigate')"
                >
                  {{ label }}
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>

        <p
          v-else
          class="flex w-full px-6 py-5"
        >
          {{ $t('search.no_results') }}
        </p>
      </div>
    </div>
  </TransitionsFadeUp>
</template>

<script lang="ts" setup>
import type {SearchResults} from '~/components/app/header/search/index.vue';

const props = defineProps<{
  expanded: boolean,
  searchTerm: string,
  results: SearchResults,
  selectedIndex: number,
}>();

defineEmits<{
  (e: 'navigate'): void
}>();

const groupedResults = computed(() => {
  const groupedResults: SearchResults = {};

  for (const [index, result] of Object.entries(props.results)) {
    const resultsGroup = groupedResults[result.__typename] ?? [];

    const label = result.label;

    resultsGroup.push({
      index: +index,
      ...result,
    });

    groupedResults[result.__typename] = resultsGroup;
  }

  return groupedResults;
});

const hasResults = computed(() => Object.values(props.results).length > 0);

const resultRefs = ref<HTMLElement[]>([]);

watchEffect(() => {
  resultRefs.value[props.selectedIndex]?.scrollIntoView({
    block: 'center',
  });
});
</script>
