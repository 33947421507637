<template>
  <div class="flex items-center gap-6">
    <a
      v-for="social in socials"
      :key="social.to"
      :href="social.to"
      target="_blank"
    >
      <UiIcon
        class="text-grey hover:text-primary"
        :name="'fa6-brands:'+social.icon"
        :aria-label="social.icon"
      />
    </a>
  </div>
</template>

<script lang="ts" setup>
const {settings} = useSofieSettings();

const socials = computed(() => [
  {icon: 'instagram', to: settings.value.instagram},
  {icon: 'facebook', to: settings.value.facebook},
  {icon: 'pinterest', to: settings.value.pinterest},
].filter(({to}) => !!to));
</script>
