<template>
  <div
    ref="rootEl"
    class="relative h-full w-fit text-lg font-medium"
  >
    <div
      class="nuxt-link inline-flex cursor-pointer items-center transition-all"
      :class="open ? 'rounded-b-none rounded-t-lg' : 'hover:!bg-grey-400 rounded-full'"
      @click="toggleDropdown"
    >
      <div class="flex items-center justify-between gap-3">
        <div class="select-none uppercase">
          {{ locale }}
        </div>
        <UiIcon
          name="fa6-solid:chevron-down"
          class="transition-transform duration-150"
          :class="{
            '-rotate-180': open,
          }"
          size="small"
        />
      </div>
    </div>
    <TransitionsFadeUp>
      <div
        class="absolute right-0 z-10 flex w-full translate-y-full select-none flex-col rounded-b-lg bg-white transition-all"
        :class="open ? 'bottom-0 opacity-100 drop-shadow-xl' : 'bottom-6 pointer-events-none opacity-0'"
      >
        <a
          v-for="(item, index) in availableLocales"
          :key="index"
          class="cursor-pointer rounded-lg px-6 py-3 text-left uppercase hover:bg-grey-400 xl:px-5"
          :href="switchLocalePath(item.code)"
        >
          {{ item.code }}
        </a>
      </div>
    </TransitionsFadeUp>
  </div>
</template>

<script lang="ts" setup>
const {locales, locale, setLocale} = useI18n();

const open = ref(false);

const availableLocales = computed(() => {
  return locales.value.filter((i: { code: string; }) => i.code !== locale.value);
});

function toggleDropdown() {
  open.value = !open.value;
}

const rootEl = ref<HTMLElement>();

onClickOutside(rootEl, () => open.value = false);

function updateLocale(code: string) {
  setLocale(code);

  open.value = false;
}
</script>

<style scoped>
.nuxt-link {
  @apply py-4 px-6 bg-white text-grey transition h-full;
}
</style>
