<template>
  <div
    v-if="$route.fullPath !== '/'"
    class="container relative z-10 mb-16"
  >
    <UiButton
      icon="AngleUp"
      icon-size="small"
      shape="pill"
      type="primary"
      class="z-20 ml-auto !bg-grey hover:!bg-grey-800 md:bottom-10 md:right-10 lg:bottom-16 lg:right-16"
      :class="{'pointer-events-none opacity-0' : !isScrolled}"
      @click="scrollToTop"
    />
  </div>
  <div class="rounded-t-4xl border-t border-grey-400 bg-white py-6 print:hidden sm:py-10 md:py-16">
    <div class="grid-gap container grid grid-cols-12 gap-y-8">
      <div class="col-span-12 space-y-6 text-base md:col-span-7">
        <p>
          {{ $t('footer.explainer') }}
        </p>
        <p>
          <i18n-t keypath="footer.contact_cta">
            <template #email>
              <a href="mailto:hello@365daysofflowers.com">
                hello@365daysofflowers.com
              </a>
            </template>
          </i18n-t>
        </p>
        <AppFooterSocialIcons />
        <NuxtLink
          :to="localePath({name: 'disclaimer-and-terms-of-use'})"
          class="inline-block text-grey underline hover:text-primary max-sm:text-sm"
        >
          {{ $t('footer.terms_and_conditions') }}
        </NuxtLink>
      </div>

      <div class="col-span-12 max-md:-order-1 md:col-span-5">
        <div
          v-if="!user"
          class="space-y-6 rounded-4xl bg-primary-400 px-8 py-10"
        >
          <div class="font-lato text-2xl font-semibold text-primary">
            {{ $t('footer.subscribe_cta.text') }}
          </div>

          <UiButton
            type="primary"
            :label="$t('footer.subscribe_cta.subscribe')"
            :to="{name: 'subscribe'}"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">

const {y} = useWindowScroll();
const isScrolled = computed(() => y.value > 100);

const user = useSofieUserWhenAuthenticated();

function scrollToTop() {
  window.scrollTo(0, 0);
}
</script>
