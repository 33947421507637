<template>
  <TransitionsFadeLeft>
    <div
      v-show="open"
      class="fixed inset-0 z-[800] block overflow-auto overscroll-contain rounded-b-lg bg-secondary-600 lg:hidden"
    >
      <div class="container mt-20 flex grow flex-col gap-6 pb-8 sm:mt-32">
        <div class="space-y-4">
          <div class="flex flex-col font-lato text-lg font-medium">
            <NuxtLink
              v-for="(item, index) in menu"
              :key="index"
              :to="localePath(item.url)"
              class="border-b border-secondary-800 py-6 text-2xl text-grey no-underline"
              active-class="text-primary"
            >
              {{ item.label }}
            </NuxtLink>
          </div>

          <AppHeaderLangSelector />
        </div>

        <div
          v-if="!user"
          class="mt-auto space-y-6 rounded-3xl bg-white px-8 py-10"
        >
          <p
            class="font-lato text-2xl font-semibold text-primary"
          >
            {{ $t('footer.subscribe_cta.text') }}
          </p>

          <UiButton
            type="primary"
            :label="$t('footer.subscribe_cta.subscribe')"
            :to="{name: 'subscribe'}"
          />
        </div>
      </div>
    </div>
  </TransitionsFadeLeft>
</template>

<script lang="ts" setup>
import type {RouteLocationRaw} from 'vue-router';

const user = useSofieUserWhenAuthenticated();

defineProps<{
  menu: Array<{
    label: string
    url: RouteLocationRaw
  }>
}>();

const open = defineModel<boolean>('open', {
  required: true,
});

const route = useRoute();

watch(() => route.fullPath, () => open.value = false);
</script>
