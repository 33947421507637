<template>
  <UiLoader
    ref="rootEl"
    class="relative"
    :loading="loading"
  >
    <button
      v-if="!alwaysExpanded"
      class="z-10 flex aspect-square h-full w-[4.5rem] cursor-pointer items-center justify-center rounded-full bg-white p-4 transition-all hover:bg-grey-400"
      type="button"
      @click="openSearch"
    >
      <UiIcon
        v-if="!expanded"
        name="ph:magnifying-glass-bold"
        class="cursor-pointer"
      />
    </button>
    <div
      class="z-10 flex h-full grow items-center gap-1 rounded-full border bg-white px-6 transition-all"
      :class="[
        !alwaysExpanded ? 'absolute right-0 top-0 border-grey' : 'relative border-grey-400 py-5',
        expanded || alwaysExpanded ? 'w-80 opacity-100' : 'pointer-events-none w-0 opacity-0',
        {'flex h-full w-full grow items-center gap-1 rounded-full border border-grey bg-white px-6 transition-all' : alwaysExpanded}
      ]"
      @click.stop
    >
      <FormKit
        type="text"
        inner-class="$reset border-none"
        input-class="$reset text-lg p-0 w-full focus:ring-0 outline-none"
        outer-class="$reset mb-0 grow z-20"
        :placeholder="$t('search.query')"
        @focus="expanded = true"
      >
        <template #input="{attrs, classes}">
          <input
            ref="searchRef"
            v-model="query"
            v-bind="attrs"
            :class="[classes.input, 'appearance-none']"
            type="search"
            @keydown.up="moveSelection(-1)"
            @keydown.down.prevent="moveSelection(1)"
            @keydown.enter.prevent="selectResult"
          >
        </template>
      </FormKit>

      <button
        v-if="expanded"
        class="cursor-pointer"
        @click="closeSearch"
      >
        <UiIcon
          name="iconamoon:sign-times-bold"
        />
      </button>
    </div>

    <AppHeaderSearchResults
      :expanded="expanded"
      :search-term="query"
      :results="results"
      :selected-index="selectedIndex"
      @close="expanded = false"
      @navigate="search"
    />
  </UiLoader>
</template>

<script lang="ts" setup>
import { type SearchQuery, useSearchQuery} from '~/graphql/graphql';
import type {ValuesType} from 'utility-types';
import type {RouteLocationRaw} from 'vue-router';
import {useGtm} from '@gtm-support/vue-gtm';

defineProps<{
  alwaysExpanded?: boolean,
}>();

const searchRef = ref<HTMLElement>();
const expanded = ref(false);
const query = ref('');

const selectedIndex = ref(-1);

const {result, loading} = useSearchQuery({
  query,
}, () => ({
  debounce: 500,
  enabled: !!query.value,
  keepPreviousResult: true,
}));

export type SearchResults = Partial<Record<ValuesType<SearchQuery['search']>['__typename'], Array<{
  index: number,
  label: string,
  to: RouteLocationRaw
}>>>

const results = computed(() => {
  if (!query.value) {
    return [];
  }

  return result?.value?.search.map((result) => {
    let resultParsed: {label: string, to: RouteLocationRaw};

    if (result.__typename === 'Flower') {
      resultParsed = {
        label: result.title_latin,
        to: {name: 'flowers-slug', params: {slug: result.slug}},
      };
    } else if (result.__typename === 'FlowerCultivar') {
      resultParsed = {
        label: result.title,
        to: {
          name: 'flowers-slug',
          params: {slug: result.flower.slug},
          query: {cultivar: result.uuid},
        },
      };
    } else {
      resultParsed = {
        label: result.title_overview,
        to: {name: 'stories-slug', params: {slug: result.slug}},
      };
    }

    return {
      __typename: result.__typename,
      ...resultParsed,
    };
  }) ?? [];
});

const searchFocus = defineModel<boolean>('searchFocus', {
  default: false,
});

const rootEl = ref<HTMLElement>();

onClickOutside(rootEl, () => expanded.value = false);

const moveSelection = (direction: number) => {
  selectedIndex.value += direction;

  if (results.value.length <= selectedIndex.value) {
    selectedIndex.value = 0;
  } else if (selectedIndex.value < 0) {
    selectedIndex.value = results.value.length - 1;
  }
};

const localePath = useLocalePath();
function selectResult() {
  const selectedResult = results.value[selectedIndex.value];

  if (!selectedResult) {
    return;
  }

  navigateTo(localePath(selectedResult.to));

  expanded.value = false;
  searchFocus.value = false;
}

function openSearch() {
  expanded.value = true;
  searchRef.value?.focus();
}

const gtm = useGtm();

function search() {
  gtm?.trackEvent({
    event: 'search',
    search_term: query.value,
  });

  closeSearch();
}

function closeSearch() {
  expanded.value = false;
  searchFocus.value = false;
}

watch(searchFocus, (newValue) => {
  if (newValue === true) {
    nextTick(() => openSearch());
  }
});

</script>

<style scoped>
input[type="search"]::-webkit-search-cancel-button {
  appearance: none;
}
</style>
