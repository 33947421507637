<template>
  <Transition
    enter-active-class="transition-all duration-300"
    leave-active-class="transition-all duration-300"
    leave-to-class="translate-x-full"
    enter-from-class="translate-x-full"
  >
    <slot />
  </Transition>
</template>
