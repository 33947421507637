<template>
  <div class="z-[900] flex items-center gap-1">
    <AppHeaderSearch
      v-model:search-focus="searchFocus"
      class="max-lg:hidden"
    />

    <button
      class="z-10 flex aspect-square w-12 cursor-pointer items-center justify-center rounded-full bg-white p-2 transition-all hover:bg-grey-400 sm:w-[4.5rem] lg:hidden"
      type="button"
      @click="toggleSearchFocus"
    >
      <UiIcon
        name="ph:magnifying-glass-bold"
        class="cursor-pointer max-lg:h-5 max-sm:h-4"
      />
    </button>

    <AppHeaderLangSelector class="max-lg:hidden" />

    <NuxtLink
      :to="localePath({name: status === 'authenticated' ? 'dashboard' : 'auth-login'})"
      class="nuxt-link aspect-square hover:bg-grey-400"
      active-class="!bg-grey !text-white"
    >
      <UiIcon
        name="fa6-regular:user"
        class="max-lg:h-5 max-sm:h-4"
      />
      <div
        v-if="status === 'authenticated'"
        class="absolute right-2.5 top-2.5 h-2 w-2 rounded-full bg-green-500 text-center text-white lg:right-4 lg:top-4 xl:right-5 xl:top-5"
      />
    </NuxtLink>
    <NuxtLink
      :to="localePath({name: 'collections'})"
      class="nuxt-link aspect-square hover:bg-grey-400"
      active-class="!bg-grey !text-white"
    >
      <UiIcon
        name="fa6-regular:heart"
        class="max-lg:h-5 max-sm:h-4"
      />
      <div
        class="absolute right-0 top-0 flex h-4 w-4 items-center justify-center rounded-full bg-primary text-xs font-medium text-white sm:-right-1.5 sm:-top-1.5 sm:h-6 sm:w-6 lg:-right-1 lg:-top-1 xl:right-0 xl:top-0"
      >
        {{ entriesCount }}
      </div>
    </NuxtLink>

    <div
      class="relative flex aspect-square w-12 cursor-pointer items-center justify-center rounded-full bg-white px-6 py-3 text-grey transition hover:bg-grey-400 sm:w-[4.5rem] lg:hidden"
      @click="toggleMobileMenu"
    >
      <div>
        <span
          class="absolute inset-x-0 mx-auto h-0.5 w-4 rounded-full bg-grey transition ease-in-out"
          :class="open ? 'rotate-45' : '-translate-y-0.5'"
        />
        <span
          class="absolute inset-x-0 mx-auto h-0.5 w-4 rounded-full bg-grey transition ease-in-out"
          :class="open ? '-rotate-45' : 'translate-y-0.5'"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {useCollectionsQuery} from '~/graphql/graphql';

const open = defineModel<boolean>('open', {
  default: false,
});

const searchFocus = defineModel<boolean>('searchFocus', {
  default: false,
});

const {status} = useAuth();

const {result: collectionsResult} = useCollectionsQuery();

const entriesCount = computed(() => {
  if (status.value === 'authenticated') {
    return collectionsResult.value?.collections.length ?? 0;
  }

  // When logged out users only have a default Favorites collection, which should always be available.
  return collectionsResult.value?.collections[0]?.entries_count ?? 0;
});

function toggleMobileMenu() {
  searchFocus.value = false;
  open.value = !open.value;
}

function toggleSearchFocus() {
  open.value = false;
  searchFocus.value = true;
}
</script>

<style scoped>
.nuxt-link {
  @apply flex items-center justify-center relative w-12 sm:w-[4.5rem] bg-white hover:bg-grey-400 text-grey p-2 rounded-full transition;
}
</style>
